import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { media } from '../../../styles/breakpoints'
import { colors } from '../../../styles/colors'
import LeadStore from '../../stores/LeadStore/LeadStore'

export interface ILeadsInput {
  store: LeadStore,
  shouldHaveLeadsText: boolean,
  placeholderText?: string,
  buttonText?: string,
  className?: string,
  inputFocus?: Function,
  fromModal: boolean
}

export const LeadsInput = observer((props: ILeadsInput) => {
  const { t: translate } = useTranslation('lead')
  const { locale } = useRouter()
  const store = props.store

  return (
    <Container>
      {props.shouldHaveLeadsText
        ? <Text className="leads-text" id="leads-text">
          {translate('leads-text')}
        </Text>
        : <></>
      }
      <Div className={props.className ?? 'leads-inputs-container'} id="leads-inputs-container">
        <InputText
          type="email"
          placeholder={props.placeholderText ?? translate('email-placeholder')}
          isValidEmail={store.isValidEmail}
          onChange={e => {
            store.addEmail(e.target.value)
          }}
          onKeyDown={e => {
            if (e.code === 'Enter') {
              store.sendEmail(locale, props.fromModal)
            }
          }}
          value={store.email}
          id="email-input"
          className="email-input"
          customPlaceholderSize={props.placeholderText != null}
          onFocus={() =>{
            if(props.inputFocus) {
              props.inputFocus()
            }
          }}
        />
        <InputButton
          type="button"
          value={props.buttonText ?? translate('send-button')}
          disabled={!store.isValidEmail}
          isValidEmail={store.isValidEmail}
          onClick={() => {
            store.sendEmail(locale, props.fromModal)
            if(props.inputFocus) {
              props.inputFocus()
            }
          }}
          id="button-input"
          className="button-input"
        />
      </Div>
      <Span isValid={store.isValidEmail} className="feedbackMessage" id="feedbackMessage">
        {translate(store.feedbackMessageKey)}
      </Span>
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;

  ${media.tabMUp} {
    max-width: 80%;
  }

  ${media.deskGUp} {
    max-width: 595px;
  }
`

const Div = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${media.tabMUp} {
    height: 60px;
  }

  ${media.deskGUp} {
    height: 80px;
  }
`

const BaseInput = styled.input`
  outline: none;
  border: none;
  padding: 0;
`

const InputText = styled(BaseInput) <{ isValidEmail: boolean, customPlaceholderSize?: boolean }>`
  width: calc(100% - 115px);
  padding: 10px 12px;
  font-size: 16px;
  color: ${colors.gray20};
  border-radius: 10px 0 0 10px;
  background-color: ${props => (props.isValidEmail ? '#fff' : '#FFC0C0')} !important;

  &::placeholder {
    font-size: ${props => props.customPlaceholderSize ? '12px' : '16px'};
    color: #999;
  }

  ${media.mobMUp}{
    &::placeholder {
      font-size: ${props => props.customPlaceholderSize ? '14px' : '16px'};
      color: #999;
    }
  }

  ${media.tabMUp} {
    border-radius: 15px 0 0 15px;
  }

  ${media.deskGUp} {
    width: calc(100% - 145px);
    padding: ${props => props.customPlaceholderSize ? '0 20px 5px' : '20px'};
    font-size: 20px;

    &::placeholder {
      font-size: 20px;
    }
  }
`

const InputButton = styled(BaseInput) <{ isValidEmail: boolean }>`
  appearance: none;

  min-width: 115px;
  border: 2px solid;
  border-color: ${props => props.isValidEmail ? 'white' : '#FFC0C0'};
  background-color: black;
  font-size: 14px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  border-radius: 0 10px 10px 0;

  ${media.tabMUp} {
    border-radius: 0 15px 15px 0;

  }

  ${media.deskGUp} {
    min-width: 145px;
    border: 4px solid;
    border-color: ${props => props.isValidEmail ? 'white' : '#FFC0C0'};
  }

  /* we all love safari <3 */
  @media not all and (min-resolution:.001dpcm) {
    margin: 2px 0;
  }
`

const Span = styled.span<{ isValid: boolean }>`
  position: relative;
  top: 15px;
  font-size: 16px;
  color: ${props => props.isValid ? '#13B140' : '#DD2E2E'};
  font-style: normal;

  ${media.tabMUp} {
    top: 22px;
    font-size: 18px;
  }

  ${media.deskGUp} {
    top: 30px;
    font-size: 20px;
  }
`

const Text = styled.span`
  color: #999;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.4px;
  margin-bottom: 20px;

  ${media.tabMUp} {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 300;
  }

  ${media.deskGUp} {
    max-width: 530px;
  }
`