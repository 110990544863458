import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { LocaleNamespaces } from '../../../../public/static/locale-namespaces.enum'
import { media } from '../../../styles/breakpoints'
import { CloseIcon } from '../../atoms/icons/CloseIcon'
import { SuccessIcon } from '../../atoms/icons/successIcon'
import { LeadsInput } from '../../molecules/LeadsInput'
import LeadStore from '../../stores/LeadStore/LeadStore'

const ModalRow = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  z-index: 60;
  justify-content: flex-start;

  ${media.tabMUp} {
    width: 620px;
    justify-content: flex-end;
  }
`

const BottomFixedModalContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  width: 90%;
  background-color: #111;
  height: 80px;
  z-index: 100;
  margin: 0  0  0 5%;
  border-radius: 15px 15px 0 0;
  flex-direction: row;
  padding: 10px 40px 20px 30px;

  p {
    color: #F7F7F7;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-left: 10px;
    margin-top: 10px;
  }
  
  ${media.tabMUp} {
    padding: 10px 30px;
    width: 570px;
    height: 60px;

    p {
      color: #F7F7F7;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      margin: 12px 0;
    }

    &:hover{
      cursor: pointer;
    }
  }
`

const ModalBackground = styled.div`
  position: fixed;
  z-index: 190;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0,0,0,0.4);
`

const CloseModalDiv = styled.div`
  display: flex;
  position: fixed;
  background-color: #F7F7F7;
  width: 35px;
  height: 35px;
  right: 30px;
  bottom: 58px;
  border-radius: 50px;
  filter: drop-shadow(1px -10px 10px #CCC);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 190;

  ${media.tabMUp} {
    position: relative;
    right: unset;
    bottom: unset;
    align-self: center;
    margin: 25px 25px 0 0;
    filter: unset;
    width: 30px;
    height: 30px;

    &:hover {
      cursor: pointer;
    }
  }
`

const ClosePopupDiv = styled(CloseModalDiv)`
  position: unset;
  filter: unset;
  background-color: #111;

  & > * {
    filter: invert(100%);
  }

  ${media.tabMUp} {
    background-color: #f7f7f7;

    & > * {
      filter: invert(0);
    }
  }
`

const CloseIconModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-width: 100%;
  padding: 14px;
  
  ${media.tabMUp} {
    display: none;
  }
`
const CloseIconModalContainerDesktop = styled(CloseIconModalContainer)`
  display: none;

  ${media.tabMUp} {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    min-width: 60px;
    padding: 0;
    margin: -37px -37px 0 0;
  }
`

const ModalImgContainer = styled.div`
  width: 100%;
  height: 30vh;
  border-radius: 15px 15px 0 0;
  background: #111 url('/images/modal_mobile.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${media.tabMUp} {
    height: 320px;
    min-width: 290px;
    max-width: 300px;
    border-radius: 15px 0 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const ModalText = styled.p`
  color: #F7F7F7;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  padding: 0 50px;
  margin: 22px 0;  

  ${media.mobMUp} {
    margin: 50px 0;
  }

  ${media.tabMUp} {
    margin: 24px 45px;
  }
`

const ErrorText = styled.p`
  color: #D73535;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  text-align: start;

  ${media.mobMUp} { 
    margin: 10px 0 14px;
  }
`

const ErrorTextContainer = styled.div`
  width: 90%;
  margin-bottom: -17px;

  ${media.mobMUp} {
    margin-bottom: -41px;
  }

  ${media.tabMUp} {
    display: none;
  }
`

const ErrorTextContainerDesktop = styled(ErrorTextContainer)`
  display: none;

  ${media.tabMUp} {
    display: flex;
    margin-left: 100px;
    margin-bottom: -40px;
  }
  
  ${media.deskGUp} {
    margin-left: 40px;
  }
`

const Modal = styled.div<{ flexDirection: string, alignItems: string, inputFocus: boolean }>`
  position: fixed;
  background: #111;
  width: 90%;
  height: max(400px, 65vh);
  border-radius: 15px;
  left: 5%;
  top: ${({ inputFocus }) => inputFocus ? '5%' : '20%'};
  z-index: 290;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.tabMUp} {
    height: 320px;
    width: 750px;
    top: 20%;
    left: calc(50% - 375px);
    position: fixed;
    flex-direction: ${props => props.flexDirection}; //row
    align-items: ${props => props.alignItems}; //flex-start
    justify-content: center;
  }
`

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 65px;

  ${media.mobMUp} {
    margin-top: 90px;
  }

  ${media.tabMUp} {
    margin-top: 0;
    padding-top: 65px;
    margin-right: 50px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${media.tabMUp} {
    display: flex;
    flex-direction: column;
    margin: 30px 0 0 0;
    padding-right: 30px;
  }
`
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
  z-index: 10;
  justify-content: center;

  ${media.tabMUp} {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 400px;
    padding: 0;
    margin: 0;

    span {
      display: none;
    }
  }

  .input {
    background-color: transparent;
    border-radius: 15px;
    width: 100%;
    padding: 0;
    z-index: 300;
    
    & > * {
      font-size: 14px;
      
      &::placeholder {
        font-size: 14px;
      }
    }
    
    & > input[type=button] {
      font-size: 14px;
      min-width: 120px;
      background-color: #000;
    }

    & > input[type=email] {
      font-size: 14px;

      ${media.tabMUp} {
        font-size: 20px;
      }
    }

    ${media.tabMUp} {
      margin-left: 20px;
      height: 60px;
    }
  }
`

const SuccessIconContainer = styled.div`
  margin: -40px 0 30px;

  ${media.tabMUp}{
    & > * {
      height: 70px;
      width: 90px;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 50%;

  ${media.tabMUp} {
    flex-direction: column-reverse;
    height: max-content;
    width: max-content;
  }
`

interface IFixedLeedsProps {
  title: string,
  errorText: string,
  successText: string,
  button: string,
  placeholder: string,
  store: LeadStore,
  enVersion: boolean
}

const FixedLeeds = observer((props: IFixedLeedsProps) => {
  const [shouldOpenLeadsModal, setShouldOpenLeadsModal] = useState(false)
  const [showShowPopup, setshowShowPopup] = useState(true)
  const [scrollTrigger, setScrollTrigger] = useState(0)
  const [inputFocused, setInputFocused] = useState(false)
  const { t: translate } = useTranslation(LocaleNamespaces.INSIGHTS)
  const store = props.store

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const localStorage: Storage = window.localStorage
      store.localStorage = localStorage
      // store.localStorage.clear() // add this line to clear localStorage
      store.validateLocalStorage()
    }
  }, [])

  const handleEscKey = e => {
    if (e.code === 'Escape') {
      setShouldOpenLeadsModal(false)
      if (store.successSendEmail) {
        store.closeLeadsModal()
      }
    }
  }

  const handleScrollTrigger = () => {
    if (scrollTrigger == 0) return
    if (window.scrollY > scrollTrigger && !store.closeLeads && showShowPopup) {
      setShouldOpenLeadsModal(true)
      setshowShowPopup(false)
      document.removeEventListener('scroll', handleScrollTrigger)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey, false)
  }, [shouldOpenLeadsModal, store.closeLeads, inputFocused])

  useEffect(() => {
    if (document.getElementById('scrollTrigger')?.offsetTop != null) {
      setScrollTrigger(document.getElementById('scrollTrigger').offsetTop - 200)
      document.addEventListener('scroll', handleScrollTrigger, false)
    }
  }, [scrollTrigger, showShowPopup])

  return (
    <>
      {(!shouldOpenLeadsModal && !store.closeLeads) &&
        <ModalRow>
          <BottomFixedModalContainer
            onClick={() => setShouldOpenLeadsModal(!shouldOpenLeadsModal)}
            id="rowBg"
          >
            <p>{props.title}</p>
          </BottomFixedModalContainer>
          <CloseModalDiv onClick={() => store.closeLeadsModal()} id="closeRow">
            <CloseIcon />
          </CloseModalDiv>
        </ModalRow>
      }

      {shouldOpenLeadsModal &&
        <>
          <ModalBackground
            onClick={() => {
              setShouldOpenLeadsModal(!shouldOpenLeadsModal)
              setInputFocused(false)
              if (store.successSendEmail) {
                store.closeLeadsModal()
              }
            }}
            id="modalBg"
          />

          <Modal id="modalContainer" flexDirection="row" alignItems="flex-start" inputFocus={inputFocused}>
            <ModalImgContainer onClick={() => setInputFocused(false)}>
              <CloseIconModalContainer>
                <ClosePopupDiv onClick={() => {
                  setShouldOpenLeadsModal(!shouldOpenLeadsModal)
                  setInputFocused(false)
                }} id="closePopup">
                  <CloseIcon />
                </ClosePopupDiv>
              </CloseIconModalContainer>
            </ModalImgContainer>
            <Wrapper>
              {!store.successSendEmail ?
                <>
                  <ContentContainer onClick={() => setInputFocused(false)}>
                    <ModalText>{props.title}</ModalText>

                    <InputContainer className="inputContainer">
                      <LeadsInput
                        shouldHaveLeadsText={false}
                        store={store}
                        placeholderText={props.placeholder}
                        buttonText={props.button}
                        className="input"
                        inputFocus={() => setInputFocused(true)}
                        fromModal={true}
                      />
                    </InputContainer>

                    {!store.isValidEmail &&
                      <ErrorTextContainerDesktop>
                        <ErrorText id="errorText">
                          {translate(store.feedbackMessageKey)}
                        </ErrorText>
                      </ErrorTextContainerDesktop>
                    }
                  </ContentContainer>

                  <CloseIconModalContainerDesktop>
                    <ClosePopupDiv onClick={() => {
                      setShouldOpenLeadsModal(!shouldOpenLeadsModal)
                      setInputFocused(false)
                    }} id="closePopup">
                      <CloseIcon />
                    </ClosePopupDiv>
                  </CloseIconModalContainerDesktop>
                </>
                :
                <>
                  <CenteredContainer>
                    <SuccessIconContainer id="sucessIcon">
                      <SuccessIcon />
                    </SuccessIconContainer>
                    <ModalText id="sucessText">{props.successText}</ModalText>
                  </CenteredContainer>

                  <div style={{ marginBottom: '15px' }}>
                    <CloseIconModalContainerDesktop>
                      <ClosePopupDiv
                        onClick={() => {
                          setShouldOpenLeadsModal(!shouldOpenLeadsModal)
                          store.closeLeadsModal()
                        }}
                        id="closePopup">
                        <CloseIcon />
                      </ClosePopupDiv>
                    </CloseIconModalContainerDesktop>
                  </div>
                </>
              }
            </Wrapper>
          </Modal>
        </>
      }
    </>
  )
})

export default FixedLeeds